<template>
  <p>Logout</p>
</template>

<script>
export default {
  name: 'Logout',
};
</script>

<style scoped lang="scss"></style>
